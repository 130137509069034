import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/Layout"
import TextLink from "../../components/TextLink"
import Album from '../../components/Album'
import bioPhoto from '../../images/Counterfit.jpg'
import { counterfitAlbums } from '../../data/albums'
import './style.css'

const Counterfit = () => (
  <Layout>
    <SEO title="Counterfit" />
    <div className="artist-page">
      <div className="artist-page__bio">
        <div>
          <h1>Counterfit</h1>
          <h2>Charlotte, NC // Passaic, NJ</h2>
        </div>
        <p>
        Counterfit is a collaboration between Darling Coils and Savr Dave. The music is a industrial-post wave bond that has a new album on the horizon in 2021. Influences include: ESPRIT 空想, Throbbing Gristle, NIN, The Mars Volta, Tears For Fears, J Dilla and Toro Y Moi...to name a few.
        </p>
        <div className="artist-page__bio-links">
          <TextLink href='https://soundcloud.com/counter-fit'>Soundcloud</TextLink>
        </div>
      </div>
      <div className="artist-page__photo">
        <img src={bioPhoto} alt="Counterfit" />
      </div>
      <div className="artist-page__albums">
        <h3>Albums</h3>
        <div className="artist-page__albums-list">
          {
            counterfitAlbums.map((album, index) => <Album key={index} album={album} />)
          }
        </div>
      </div>
    </div>
  </Layout>
)

export default Counterfit
